@font-face {
font-family: 'icomoon';
src: url(/_next/static/media/a7cfc145a859ea08-s.p.woff) format('woff');
font-display: block;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'icomoon Fallback';src: local("Arial");ascent-override: 93.75%;descent-override: 6.25%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_e25865 {font-family: 'icomoon', 'icomoon Fallback'
}.__variable_e25865 {--font-family-icomoon: 'icomoon', 'icomoon Fallback'
}

@font-face {
font-family: 'sfPro';
src: url(/_next/static/media/0fe7ec85885462b1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'sfPro';
src: url(/_next/static/media/d444cb7ee49237c9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'sfPro';
src: url(/_next/static/media/6ca83f1508666046-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'sfPro';
src: url(/_next/static/media/f15837ddf307a9c9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'sfPro Fallback';src: local("Arial");ascent-override: 101.52%;descent-override: 25.72%;line-gap-override: 0.00%;size-adjust: 93.79%
}.__className_97c015 {font-family: 'sfPro', 'sfPro Fallback'
}.__variable_97c015 {--font-family-sf-pro-display: 'sfPro', 'sfPro Fallback'
}

.vpn_wrapper__96WIR{display:flex;align-items:center;justify-content:center;gap:20px;background:linear-gradient(0deg, rgba(21, 12, 28, 0.5) 0%, rgba(21, 12, 28, 0.5) 100%),linear-gradient(180deg, #48008f 0%, #000 100%),#070518;width:100%;margin:auto;padding:32px 12px;min-height:100vh}.vpn_left-block__9HCZ9{display:flex;flex-direction:column;align-items:flex-start;justify-content:center;gap:80px;max-width:580px}@media(max-width: 920px){.vpn_left-block__9HCZ9{align-items:center;gap:40px;font-size:24px;line-height:28px}}.vpn_text__6u5AG{text-shadow:0px 2px 1px #070518;font-size:48px;font-style:normal;font-weight:800;line-height:normal}.vpn_text__6u5AG span[data-color=orange]{color:#ff7223}@media(max-width: 920px){.vpn_text__6u5AG{text-align:center;font-size:24px;line-height:28px}}.vpn_img-man__54CLg{display:block;height:100%;mask-image:linear-gradient(to top, transparent 0px, black 64px, black 100%)}.vpn_img-man__54CLg.vpn___mobile___4zIh{display:none}@media(max-width: 920px){.vpn_img-man__54CLg{width:55%;height:auto;font-size:24px;line-height:28px}.vpn_img-man__54CLg.vpn___mobile___4zIh{display:block}.vpn_img-man__54CLg.vpn___deskrop__uvwFx{display:none}}
