.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: linear-gradient(0deg, rgba(21, 12, 28, 0.5) 0%, rgba(21, 12, 28, 0.5) 100%), linear-gradient(180deg, #48008f 0%, #000 100%), #070518;
    width: 100%;
    margin: auto;
    padding: 32px 12px;
    min-height: 100vh;
}

.left-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 80px;
    max-width: 580px;

    @media (max-width: 920px) {
        align-items: center;
        gap: 40px;
        font-size: 24px;
        line-height: 28px;
        /* 116.667% */
    }
}

.text {
    text-shadow: 0px 2px 1px #070518;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    span[data-color="orange"] {
        color: #ff7223;
    }

    @media (max-width: 920px) {
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        /* 116.667% */
    }
}

.img-man {
    display: block;
    height: 100%;
    mask-image: linear-gradient(to top, transparent 0px, black 64px, black 100%);

    &.__mobile {
        display: none;
    }

    @media (max-width: 920px) {
        width: 55%;
        height: auto;
        font-size: 24px;
        line-height: 28px;
        /* 116.667% */

        &.__mobile {
            display: block;
        }

        &.__deskrop {
            display: none;
        }
    }
}